import * as React from 'react';
import { AppBar, Box, Toolbar, Typography, Button } from '@material-ui/core';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import HeaderNotifcation from '../../../Widget/HeaderNotification';
import Notifications from '@material-ui/icons/Notifications';
import Badge from '@mui/material/Badge';
import packgae from '../../../../package.json';

const userManager = new UserManager({
  authority: 'https://sso.al-enterprise.com/am/oauth2/realms/root/realms/internal',
  client_id: 'LMS-INT',
  redirect_uri: 'https://int.wbm.lms.opentouch.com/',
  response_type: 'code',
  scope: 'openid profile email',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export default function DashboardHeader(props) {
  //const { instance } = useMsal();
  const [notif, setNotif] = React.useState(false);
  const ref = React.useRef();

  function notificationToggle() {
    if (notif === false) {
      props.actions.setCounterValue(null, 0);
    }
    setNotif(!notif)
  }
  function onClickOutside() {
    setNotif(false);
  }
  console.log(props.notificationCounter, 'countereeeeee');
  let badge = (props.notificationCounter && props.notificationCounter.counter > 0) ? <Badge badgeContent={props.notificationCounter.counter} color={props.notificationCounter.type ? props.notificationCounter.type : 'primary'} style={{ marginRight: '30px' }}></Badge> : null;
  const handleLogout = () => {
    userManager.signoutRedirect();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        {/* <div className={`container_div64`}></div> */}
        <Toolbar style={{ backgroundColor: "#8561c5", position: 'relative' }}>
          <div style={{ width: 1080, margin: "0 auto" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              style={{ float: "left", marginTop: "5px" }}
            >
              LMS <span className={'versionTag'}>
                {packgae.version}
              </span>
            </Typography>
            <div style={{ float: 'right', display: 'flex', marginTop: '3px' }}>
              <Notifications ref={ref}
                className={props.notificationCounter && props.notificationCounter.counter > 0 ? '' : 'margin30'}
                style={{ fontSize: 35, cursor: "pointer", color: "#fff" }}
                onClick={notificationToggle}>
              </Notifications>
              {badge}
              <Button variant="text"
                style={{ fontSize: "12px", backgroundColor: "#fff", color: "#8561c5", padding: "8px" }}
                size="small"
                onClick={handleLogout}>Logout</Button>
            </div>
          </div>
          {notif && <HeaderNotifcation type={props.notificationCounter.type} onClickOutside={onClickOutside} parentRef={ref} data={props.notification} />}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
