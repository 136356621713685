import React, { useEffect } from 'react'
import {
    InputBase, styled, alpha, Button
} from '@material-ui/core';
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 2),
        border: "1px solid rgb(79, 77, 73)",
        borderRadius: "4px",
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '500px',
            '&:focus': {
                width: '500px',
            },
        },
    },
}));
const Search = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        margin: "10px auto",
        width: '500px',
    },

}));
export default function SearchComponent(props) {
    console.log(props.instance, 'Karthik')
    const [search, setSearch] = React.useState('');
    const searchButton = { display: "block", margin: "20px auto 10px auto", backgroundColor: "rgb(133, 97, 197)", color: "#fff" }
    function handleSearch() {
        console.log(process.env)
        props.performSearch(search)
        setSearch('')
    }
    function onChangeSearch(e) {
        setSearch(e.target.value)
    }
    function onKeyDown(e){
        if(e.key === 'Enter'){
            handleSearch()
        }
    }
    useEffect(() => {
        props.actions.clearFormData({})
        props.actions.setMode('');
    }, [props.actions])
    return (
        <div className={'searchContainer'}>
            <Search>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={onChangeSearch}
                    onKeyPress={onKeyDown}
                    value={search}
                    id="search"
                />
                <Button onClick={handleSearch} style={searchButton}>Search</Button>
            </Search>
        </div>
    )
}
