/* eslint-disable no-param-reassign */
import React from 'react'
import { TextField, Button, FormControlLabel, Box, Switch } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import CommonModal from '../Modals/CommonModal';
import LicenseItems from './License/LicenseItems';
import * as util from '../../Utils/Utility';
import Zoom from '@mui/material/Zoom';
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    },
}));
export default function AddForm(props) {
    const [modified,setModified] = React.useState({
        message:""
    })
    const [addLicense,setAddLicense] = React.useState(props.mode === 'ADD' ? false : true);
    const [stateToFalse,setStateToFalse] = React.useState(false);
    const [userData, setUserData] = React.useState(props.data)
    const getLicenseCount = userData.globalLicenseStatus !== undefined ? userData.globalLicenseStatus.length : 0;
    const [licenseColapse, setLicenseColapse] = React.useState(false)
    const buttonStyle = { color: "#fff", backgroundColor: "#3f51b5", minWidth: "40px", marginRight: "10px" }
    const addStyle = { color: "#fff", backgroundColor: "#3f51b5", margin: "15px 5px", width:'80px' }
    const commonStyle = { textOverflow:'ellipsis',width: "300px", display: "block", margin: "0px auto", padding: "20px 15px 15px 15px", }
    const container_style = { border: "1px solid #ccc", padding: "25px 18px", maxWidth: "450px", margin: "0 auto" };
    function changeField(e) {
        let value;
        if (e.target.id === "isActive"){
            value = e.target.checked === true ? true : false;
            console.log(value,`status`)
            if(props.mode !== 'ADD'){
                if(value === true){
                    props.changeStatus({isActive:value},userData.projectId)  
                    setUserData({ ...userData, [e.target.id]: value })              
                }
                else{
                    setStateToFalse(true)
                }
            }
            else{
                setUserData({ ...userData, [e.target.id]: value })
            }
            
        }
        else {
            value = e.target.value;
            setUserData({ ...userData, [e.target.id]: value })
        }
    }
    function changeStateToFalse(){
        props.changeStatus({isActive:false},userData.projectId)
        setUserData({ ...userData, isActive: false }) 
        setStateToFalse(false)
    }
    function setLicenseItems(e) {
        // const id = e.target.id.split('_')[0];
        // const isBool = id === 'licenseItemType' && e.target.value.toLowerCase() === 'boolean';
        // const setNumber = parseInt(e.target.id.split('_')[1])
        // const tempLicenseItems = userData.globalLicenseStatus.map((item,i) => {
        //     if (i+1 === setNumber) {
        //         item = { ...item, [id]: e.target.value }
        //     }
        //     if(!item.availableCount && id === 'licenseItemId'){
        //         item = {...item,subscribedCount:item.subscribedCount ? item.subscribedCount : ''}
        //     }
        //     if(isBool && i+1 === setNumber){
        //         item = {...item,subscribedCount:"True"}
        //     }
        //     else if(!isBool && e.target.value.toLowerCase() === "resource" && i+1 === setNumber){
        //         item = {...item,subscribedCount:item.subscribedCount ? item.subscribedCount : ''}
        //     }
        //     return item
        // })
        // setUserData({ ...userData, globalLicenseStatus: tempLicenseItems })
        let licenseIndex = parseInt(e.target.id.split('_')[1]);
        let id = e.target.id.split('_')[0];
        const tempLicenseItems = userData.globalLicenseStatus.map((item,index) => {
            let defaultType;
            if(id === 'licenseItemId'){
                defaultType = util.booleanTypeOptions.indexOf(e.target.value) >= 0 ? 'Boolean' : 'Resource';
            }
            else if(id === 'licenseItemType'){
                defaultType = e.target.value;
            }
            let subscribedCount = defaultType === 'Boolean' ? '1' : '';
            let existing = item.availableCount !== undefined;
            let currentItem = index+1 === licenseIndex
            if(currentItem){
                let value = e.target.value;
                if(id === 'subscribedCount' && e.target.value.trim() !== '')
                value = (e.target.value !== '-') ? parseInt(e.target.value):item.subscribedCount;
                item = {...item,[id]:value}
            }
            if(!existing && currentItem){
                if(id === 'licenseItemId'){
                    item.licenseItemType = defaultType;
                    item.subscribedCount = subscribedCount;
                }
                else if(id === 'licenseItemType'){
                    item.subscribedCount = subscribedCount;
                }
                else if(id === 'subscribedCount'){
                    item.subscribedCount = (e.target.value !== '' && e.target.value !== '-') ? parseInt(e.target.value):item.subscribedCount;
                }
            }
            return item;
        })
        setUserData({ ...userData, globalLicenseStatus: tempLicenseItems })
    }
    function addLicenseItem(e) {
        const data_id = parseInt(e.target.parentNode.offsetParent.getAttribute('data-id')) + 1;
        setUserData({
            ...userData, globalLicenseStatus: [...userData.globalLicenseStatus, {
                id:data_id,
                licenseItemId: "",
                licenseItemType: "Resource",
                subscribedCount:"",
                comment:""
            }]
        })

    }
    function deleteLicenseItem(e) {
        const data_id = parseInt(e.target.parentNode.offsetParent.getAttribute('data-id'));
        // eslint-disable-next-line array-callback-return
        const newLicenseItems = userData.globalLicenseStatus.filter((item,i) => {
            if ((i+1) !== parseInt(data_id))
                return item;
        })
        setUserData({
            ...userData, globalLicenseStatus: newLicenseItems
        })
    }

    function refreshForm() {
        setUserData(props.data)
    }
    function validateLicense(data){
        console.log(data,'license$$$$$$$$$$$$');
        let licenseValidation = true;
        if(data.globalLicenseStatus.length ===1){
            const keys = ['licenseItemId','subscribedCount','comment']
            let emptyCount = 0
            data.globalLicenseStatus.forEach((item,i) => {
                keys.forEach((key)=> {
                    if(item[key] === ''){
                        emptyCount++;
                    } 
                })
            })
            if(emptyCount === 0 || emptyCount === 3){
                licenseValidation = true;
            }
            else
                licenseValidation = false; 
        }
        else {
            data.globalLicenseStatus.forEach((item, i) => {
                let keys = Object.keys(item);
                keys.forEach((key) => {
                    if (item[key] !== undefined && item[key] === '') {
                        licenseValidation = false;
                    }
                })
            })
        }
        return licenseValidation;
    }
    function handleClick() {
        if(JSON.stringify(userData) === JSON.stringify(props.data)){
                setModified({status:false,message:'No changes detected'})
        }
        else {
            if(userData.projectId !== '' && userData.projectId !== undefined && userData.projectId.trim() !== ''){
                setModified({status:true,message:''})
                let licenseValidation = validateLicense(userData)
                if(licenseValidation)
                props.storeProjectDetails(userData);
                else{
                    setModified({status:false,message:'Empty input not accepted'})
                }
            }
            else{
                setModified({status:false,message:'Enter project ID'})
            }
        }
    }
    // function validateUserData(data){

    // }
    function collapseLicense() {
        if(props.mode === 'ADD' && addLicense === false)
            setAddLicense(true);
        setLicenseColapse(!licenseColapse)
    }
    
    const footer_buttons = { width: "300px", margin: "0 auto", textAlign: "center" }
    const licenseItems = userData.globalLicenseStatus && userData.globalLicenseStatus.length > 0 ? <LicenseItems
        data={userData.globalLicenseStatus}
        onChange={setLicenseItems}
        getLicenseCount={getLicenseCount}
        globalLicenseStatus={userData.globalLicenseStatus}
        commonStyle={commonStyle}
        buttonStyle={buttonStyle}
        addLicenseItem={addLicenseItem}
        deleteLicenseItem={deleteLicenseItem} 
        mode={props.mode}/> : setUserData({
            ...userData, globalLicenseStatus: [
                {
                    id: 1,
                    licenseItemId: "",
                    licenseItemType: "Resource",
                    subscribedCount: "",
                    comment:""
                }
            ]
        });
    return (
        <div className={'add_form'} style={{marginTop:'25px'}}>
            <div className={`general_container`}>
                <p className={'general_text'}>General</p>
                <LightTooltip TransitionComponent={Zoom} title={userData.projectId ? userData.projectId : ''}>
                <TextField
                    // helperText="Please enter your name"
                    id="projectId"
                    label="Project ID"
                    className="projectId"
                    inputProps={{ style: { width: 250,textOverflow:'ellipsis' } }}
                    style={commonStyle}
                    value={userData.projectId ? userData.projectId : ''}
                    onChange={changeField}
                    disabled={props.data.projectId !== undefined ? true : false}
                    fullWidth
                />
                </LightTooltip>
                {/* <TextField id="comment" label="Comments" style={commonStyle} value={userData.comment} variant="standard" onChange={changeField}/> */}
                {/* <TextField
                    style={commonStyle}
                    id="comment"
                    label="Comment"
                    value={userData.comment}
                    onChange={changeField}
                    fullWidth
                    required
                /> */}
                <p className={`statusText`}>State</p>
                <FormControlLabel style={commonStyle} control={
                    <Switch 
                        id={"isActive"} 
                        onChange={changeField} 
                        checked={userData.isActive === true ? true : false} 
                        color={"primary"}
                    />} 
                label={userData.isActive === true ? "Active" : "Inactive"} />
            </div>
            <div className={'license_container'} style={container_style}>
                <p className={"license_text"}>License Item Details</p> <button onClick={collapseLicense} className="button_collapse">{!licenseColapse ? '+' : '-'}</button>
                {<div className={licenseColapse ? '':'height_zero'}>{licenseItems}</div>}
            </div>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                {/* <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
            </Box>
            <div className={'footer_buttons'} style={footer_buttons}>
                <Button variant="contained" size='small' style={addStyle} className="addButton" onClick={handleClick}>Save</Button>
                <Button variant="contained" size='small' style={addStyle} className="addButton" onClick={refreshForm}>Cancel</Button>
            </div>
            {modified.status === false && <CommonModal mBody = {modified.message} title= {"Error field validation"} footer={"OK"} onClick={() => setModified('')} disableBackDropClick = {true}/>}
            {stateToFalse && <CommonModal mBody = {`Do you really want to deactivate the project ${userData.projectId}`} title= {"Deactivate Project"} footer={"OK"} cancel={'OK'} onClick={changeStateToFalse} onCancel={() => setStateToFalse(false)}/>}
        </div>
    )
}
