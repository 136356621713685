import React from 'react'

export default function ApplicationElement(props) {
    const [toggleItem,setToggleItem] = React.useState(false)
    function toggleLicenseElem(){
        setToggleItem(!toggleItem)
    }
    return (
            <React.Fragment>
                <div className={'app_license_box'}>
                    <p className="license_text">License Item Details</p>
                    <button className={'button_collapse'} onClick={toggleLicenseElem}>{toggleItem ?'-':'+'}</button>
                    {toggleItem && props.appLicenseItems}
                </div>
            </React.Fragment>
        )
}
