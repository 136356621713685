import React from 'react'
import { TextField, Button } from '@material-ui/core';
import GeneralApp from './generalApp.js'
import LicenseApp from './licenseApp.js'
import ApplicationElement from './ApplicationElement';
import CommonModal from '../Modals/CommonModal';
export default function Application(props) {
    console.log(props.data, '$$$$$$$$$');
    const [appData, setAppData] = React.useState(props.data.applicationLicenseStatus);
    const [appModal, setAppModal] = React.useState(false);
    const [alertModal, setAlertModal] = React.useState(false);
    const [deleteAppId, setDeleteAppId] = React.useState('')
    const [appId, setAppId] = React.useState('');
    const [error, setError] = React.useState(false)
    React.useEffect(() => {
        setAppData(props.data.applicationLicenseStatus)
        setAppModal(false)
    }, [props.data.applicationLicenseStatus])
    // const [licenseColapse,setLicenseColapse] = React.useState(false)
    const addStyle = { color: "#fff", backgroundColor: "#3f51b5", margin: "15px 5px" }
    // const buttonStyle = { color: "#fff", backgroundColor: "#3f51b5", minWidth: "40px", minHeight:"36px", marginRight: "10px", padding: "0" }
    // function addApplication2(e) {
    //     const appid = parseInt(e.target.parentNode.offsetParent.parentNode.getAttribute('data-id'))+1;
    //     setAppData([...appData,{
    //         applicationId:""
    //     }])
    // }
    function addApplication(e) {
        setAppModal(true);
    }
    function deleteApplication(e) {
        const appid = parseInt(e.currentTarget.parentNode.offsetParent.getAttribute('data-id'));
        setDeleteAppId(appData[appid - 1].applicationId)
        // console.log('deleteApp',appData[appid-1].applicationId);
        setAlertModal(true)


        // const newAppData = appData.filter((app,i) => {
        //     return i+1 !== parseInt(appid)
        // })
        // if(newAppData.length === 0){
        //     setAppData([{
        //         applicationId:""
        //     }])
        // }
        // else
        // setAppData(newAppData)
    }
    function refreshForm() {
        setAppData(props.data.applicationLicenseStatus)
    }
    // function saveFormDetails(){
    //     props.save(appData,props.projectId);
    // }
    function changeAppId(e) {
        const appid = e.target.id.split('_')[1];
        const newAppData = appData.map((app, i) => {

            if (i + 1 === parseInt(appid))
                app.applicationId = e.target.value
            return app
        })
        setAppData(newAppData)
    }
    // function collapseLicenseItems(e){
    //     let id = e.target.id;
    // }
    function modifyAppId(e) {
        setAppId(e.target.value)
    }
    function saveApplication() {
        console.log(appId, 'application ID')
        if (appId && appId.trim() !== '') {
            props.save(appId, props.projectId);
            setAppModal(false);
        }
        else {
            setError(true)
            setAppModal(false);
        }
    }
    const licenseWrapper = { margin: "10px auto", width: "450px", padding: "15px", position: "relative" }
    const button_wrapper = { position: "absolute", top: "calc(50% - 28.18px)", right: "-110px", padding: "10px 0px", backgroundColor: "#fff" }
    const footer_buttons = { width: "300px", margin: "0 auto", textAlign: "center" }
    const inputText = <TextField
        placeholder='Application ID'
        onSubmit={modifyAppId}
        onChange={modifyAppId}
    />
    const applications = (appData && appData.length > 0) ? appData.map((app, i) => {
        // let showAdd = appData.length === i+1 && appData.length < props.data.applicationLicenseStatus.length + 1;
        let disabled = props.data.applicationLicenseStatus.length >= i + 1;
        let appLicenseItems = app.licenseItemUsage && app.licenseItemUsage.map((license, i) => {
            return (
                <LicenseApp index={i} license={license} changeAppId={changeAppId} applicationId={app.applicationId} lastaccessdate={app.lastAccessDate}/>
            )
        })
        return (
            <div key={i + 1} data-id={i + 1} className="app_wrapper" style={licenseWrapper}>
                <div className={`general_container`}>
                    <GeneralApp app={app} index={i} changeAppId={changeAppId} disabled={disabled} {...props} />
                </div>
                {/* <ApplicationElement appLicenseItems={appLicenseItems}/> */}
                {appLicenseItems && appLicenseItems.length > 0 && <ApplicationElement appLicenseItems={appLicenseItems} />}
                <div className="button_wrapper" style={button_wrapper}>
                    {/* {showAdd && <Button id={"plus_license"} style={buttonStyle} onClick={addApplication}>+</Button>} */}
                    {sessionStorage.getItem('environment') && <Button id={"plus_license"} variant="outlined" color="error" style={{ border: "1px solid rgb(211, 47, 47)", color: "rgb(211, 47, 47)" }} onClick={deleteApplication}>Delete</Button>}
                </div>
            </div>
        )
    }) : null

    return (
        <div>
            {sessionStorage.getItem('environment') !== "PROD" && <Button onClick={addApplication} variant="outlined" color="success" style={{ margin: "10px 0px", border: "1px solid green", color: "green" }}>
                Add Application
            </Button>}
            {appData && appData.length > 0 && applications}
            <div className={"footer_buttons"} style={footer_buttons}>
                {/* <Button variant="contained" size='small' style={addStyle} className="addButton" onClick={saveFormDetails}>Save</Button> */}
                {/* {appData && appData.length > 0 && <Button variant="contained" size='small' style={addStyle} className="addButton" onClick={refreshForm}>Reset</Button>} */}
            </div>
            {appModal && <CommonModal onClick={saveApplication} onCancel={() => setAppModal(false)} mBody={inputText} title={"Add Application"} footer={"OK"} cancel={'OK'} />}
            {
                alertModal && <CommonModal mBody={`Are you sure want delete application ${deleteAppId}`} onCancel={() => setAlertModal(false)} onClick={() => {
                    setAlertModal(false)
                    props.deleteApp(props.projectId, deleteAppId)
                }
                } title={'Error validation'} footer={'OK'} cancel={'OK'} />
            }
            {error && <CommonModal onClick={() => setError(false)} mBody={'Invalid input parameters'} title={"Error validation"} footer={"OK"} />}
        </div>
    )
}
