import jwt_decode from 'jwt-decode';
import { publicKeyAzure } from './constants';
import { decode } from 'jsonwebtoken';
export const gridConstants = {
    "projectId": "Project ID",
    "status": "Status",
    "licenseType": "License Count"
}
export const errorMessages = {
    invalid: "Give valid input"
}
export const booleanTypeOptions = ['vna-user-f', '8770-f', 'selfcare-f', 'api-management-f', 'identity-f', 'sosm-f'];

const labels = {
    1: "TRUE",
    2: "FALSE",
    Boolean: "BOOLEAN",
    Resource: "RESOURCE"
}
export const getLabelValues = (value) => {
    let retValue = labels[value];
    let result;
    retValue === undefined ? result = value : result = retValue;
    return result;
}
export const getHeaders = () => {
    let token = sessionStorage.getItem('accessToken')
    return {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
    }
}
export const getTokenAndKey = async () => {
    let token = sessionStorage.getItem('accessToken')
    let decoded = jwt_decode(token);
    let keys = await fetch(publicKeyAzure)
    let jsonKeys = await keys.json()
    console.log(decoded, jsonKeys.keys, 'decoded')

    var base64Url = token.split('.')[0];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let publicKeyObject = jsonKeys.keys.filter(key => { return key.kid === JSON.parse(jsonPayload).x5t })
    let publicKey = publicKeyObject[0].x5c;
    let publicKeyCert = `-----BEGIN CERTIFICATE-----\n${publicKey[0]}\n-----END CERTIFICATE-----`;
    console.log(token, publicKeyCert);
    console.log(
        decode(token), //works fine!
        'decoded$$$$$$'
    );
}
export const constructInvalidErrorObject = (message) => {
    return {
        status: 405,
        message: message
    }
}
export const groupBy = (array, property) => {
    var hash = {};
    for (var i = 0; i < array.length; i++) {
        if (!hash[array[i][property]])
            hash[array[i][property]] = [];
        hash[array[i][property]].push(array[i]);
    }
    return hash;
}
export const getAllItemFromObjects = (status) => {
    let objectIds = Object.keys(status);
    let result = [];
    objectIds.forEach((state) => {
        result = [...result, ...status[state]]
    })
    return result;
}
export const convertToBool = (flag) => {
    if ((typeof (flag) === 'string' && flag.toLowerCase() === '1') || flag === 1) {
        return 1;
    }
    else if ((typeof (flag) === 'string' && flag.toLowerCase() === '0') || flag === 0) {
        return 0
    }
    else {
        return new Error('Invalid boolean type')
    }
}
export const convertToString = (bool) => {
    if (bool === 0)
        return 'False';
    else if (bool === 1)
        return 'True';
    else
        return bool;
}
export const dropDown = [
    { label: "8770-f", type: "Boolean" },
    { label: "ale-softphone-f", type: "Resource" },
    { label: "api-management-f", type: "Boolean" },
    { label: "api-rsi-f", type: "Resource" },
    { label: "api-tel-f", type: "Resource" },
    { label: "attendant-4059-f", type: "Resource" },
    { label: "business-recording-f", type: "Resource" },
    { label: "dispatch-console-conference-f", type: "Resource" },
    { label: "dispatch-console-user-f", type: "Resource" },
    { label: "enterprise-recording-f", type: "Resource" },
    { label: "identity-f", type: "Boolean" },
    { label: "media-serv-f", type: "Resource" },
    { label: "recording-api-f", type: "Resource" },
    { label: "room-hospitality-f", type: "Resource" },
    { label: "selfcare-f", type: "Boolean" },
    { label: "sip-trunk-f", type: "Resource" },
    { label: "sosm-f", type: "Boolean" },
    { label: "standard-recording-f", type: "Resource" },
    { label: "vaa-port-f", type: "Resource" },
    { label: "vna-port-f", type: "Resource" },
    { label: "vna-user-f", type: "Boolean" },
    { label: "voice-agent-f", type: "Resource" },
    { label: "voice-enterprise-f", type: "Resource" }
]

