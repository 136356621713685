import * as React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

export default function Alerts(props) {
  const [show, setShow] = React.useState(true)
  const type = {
    failure:"error",
    success:"success",
    warning:"warning"
  }
  const message = {
    failure:"Failed",
    success:"Success",
    warning:"Warning"
  }
let headerMsg = message[props.data.type];
let severity = type[props.data.type]
  React.useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)      
      props.action.clearNotification()
    }, 3000)

    return () => {
      setShow(true)
      clearTimeout(timeId)
    }
  }, [props.action]);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <div className={"alert_box"}>
      <Alert severity={severity}>
        <AlertTitle>{headerMsg}</AlertTitle>
        <strong>{props.data.message}</strong>
      </Alert>
    </div>
  )
}