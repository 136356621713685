import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import configureStore from './store';
import './App.css';
import { UserManager } from "oidc-client-ts";
import { msalConfig } from "./config";
import RedirectCallback from './authService';
import DashboardContainer from './Components/Dashboard/DashboardContainer';

export const msalInstance = new UserManager(msalConfig);
const syncHistoryWithStore = (store, history) => {
  const { router } = store.getState();
  if (router && router.location) {
    history.replace(router.location);
  }
};

const initialState = {};
const routerHistory = createMemoryHistory();
const store = configureStore(initialState, routerHistory);
syncHistoryWithStore(store, routerHistory);

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check user session status and update authentication state
    msalInstance.getUser().then(user => {
      console.log(user, 'user')
      if (user) {
        if (user?.profile?.sub) {
          let userData = user.profile.sub;
          console.log(userData, 'userData');
          setIsAuthenticated(true); // Update state
          console.log('Setting isAuthenticated to true');
        } else {
          console.error("User profile or 'sub' property missing");
        }
      }
      else {
        // Attempt silent login or redirect if no user found
        console.log("No user found, attempting redirect...");
        setTimeout(() => msalInstance.signinRedirect(), 1000);
      }
    }).catch(error => {
      console.error("Error checking user:", error);
    });

    // Set up OIDC event listeners
    const handleUserLoaded = (user) => {
      console.log("User loaded:", user);
      setIsAuthenticated(true);
    };

    const handleSilentRenewError = (error) => {
      console.error("Silent renew error:", error);
    };

    const handleUserSignedOut = () => {
      console.log("User signed out");
      setIsAuthenticated(false);
      msalInstance.removeUser();  // Clear stored user data
    };

    // Attach event listeners
    msalInstance.events.addUserLoaded(handleUserLoaded);
    msalInstance.events.addSilentRenewError(handleSilentRenewError);
    msalInstance.events.addUserSignedOut(handleUserSignedOut);

    // Clean up event listeners on unmount
    return () => {
      msalInstance.events.removeUserLoaded(handleUserLoaded);
      msalInstance.events.removeSilentRenewError(handleSilentRenewError);
      msalInstance.events.removeUserSignedOut(handleUserSignedOut);
    };
  }, []);

  useEffect(() => {
    console.log("isAuthenticated changed to:", isAuthenticated);
  }, [isAuthenticated]);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            {/* Define the RedirectCallback route to ensure itÃ¢â‚¬â„¢s always accessible */}
            <Route path="/" element={<RedirectCallback />} />

            {/* Main application routes, conditionally rendered based on authentication */}
            {isAuthenticated ? (
              <>
                <Route exact path="/" component={DashboardContainer} />
                <Route path='/dashboard' component={DashboardContainer} />
              </>  // Define your authenticated routes directly here
            ) : (
              <Route path="*" element={<span>Loading...user is not authenticated</span>} />
            )}
          </Routes>
        </div>
      </Router>
    </Provider>
  );

};

export default App;
