import * as React from 'react';
import { InputLabel, FormControl, NativeSelect, Box } from '@material-ui/core';

export default function SelectOption(props) {
    let options = props.options.map(item => {
        return <option value={item}>{item}</option>
    })
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{fontSize:'20px',marginTop:"6px"}}>
          {props.label}
        </InputLabel>
        <NativeSelect
        disabled={props.disabled}
        style={{margin:"36px 0px 14.5px 12px",width:"300px"}}
        defaultValue={props.defaultValue ? props.defaultValue : props.options[0]}
          inputProps={{
            name: props.label,
            id: props.id,
          }}
          value={props.defaultValue}
          onChange={props.onChange}>
            {options}
            
        </NativeSelect>
      </FormControl>
    </Box>
  );
}