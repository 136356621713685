import React from 'react'
import DashboardHeaderTool from './DashboardHeaderTool';
import AddProjectForm from "../../Project/AddProject"
import SearchComponent from '../Search/Search';
import CommonModal from '../../Modals/CommonModal';

export default function DashboardBody(props) {
    const containerStyle = { height:"calc(100vh - 105px)", margin: "5px auto" }
    // const [data,setData] = React.useState({})
    console.log('$$$$$$$$$$$',props)
    React.useEffect(() => {
    }, [props.actions])
    function setDetailView(isDetail){
        props.actions.setDetailView(isDetail) 
        // setData({})
    }
    function deleteProject(projectId){
        props.actions.deleteProject(projectId);
    }
    function performSearch(search){
        console.log(search,'searchIdddddddddd')
        props.actions.findProject(search)
    }
    function setMode(mode){
        props.actions.setMode(mode);
    }
    return (        
        <div style={containerStyle}>
            <DashboardHeaderTool setDetailView={setDetailView} deleteProject={deleteProject} isDetail={props.validProject && props.isDetail} currentData={props.formData} setMode={setMode} />
            {(props.validProject && props.isDetail) ? <AddProjectForm environment={props.environment} actions={props.actions} detailViewData={props.formData} mode={props.mode}/> : <SearchComponent performSearch={performSearch} setDetailView={setDetailView} actions={props.actions} />}
            {props.validProject === false && <CommonModal mBody={"Please enter valid project ID"} title={"Invalid Project ID"} footer={"OK"} />}
        </div>
    )
}
