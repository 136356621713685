import * as React from 'react';
import {CircularProgress} from "@material-ui/core";

export default function SimpleBackdrop() {

  return (
    <div className='loader_backdrop'>
        <CircularProgress color="inherit" style={{marginTop:'21%'}}/>
    </div>
  );
}