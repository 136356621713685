import React from 'react';
import { Button } from '@material-ui/core';
import CommonModal from '../../Modals/CommonModal';

export default function DashboardHeaderTool(props) {
    const backStyle = {color:"#8561C5",backgroundColor:"#fff",margin:"15px 5px",border: "2px solid #8561C5",boxShadow:"none"}
    const addStyle = {color:"#68b223",backgroundColor:"#fff",margin:"15px 5px",border: "2px solid #68b223",boxShadow:"none",height:"40px"}
    const [deleteProject,setDeleteProject] = React.useState(false);
    function handleClick(e){
        e.target.innerText.indexOf('ADD') >=0 && props.setMode('ADD')
        if(e.target.closest('button').className.indexOf('addButton') > -1)
        props.setDetailView(true)
        else
        props.setDetailView(false)
    }
    function removeProject(){
        props.setDetailView(false)
        setDeleteProject(false)
        props.deleteProject(props.currentData.projectId)
    }
    const deleteStyle = {backgroundColor:"rgb(211, 47, 47)",color:"#fff",float:"right",margin:"15px 5px"}
    return (
        <div id='header_project' style={{width:"1080px",margin:"0 auto"}}>
            { props.isDetail ? <React.Fragment>
                <Button variant="contained" size='medium' className="backButton" style={backStyle} onClick={handleClick}>Back</Button>
                {sessionStorage.getItem('environment') !== "PROD" && props.currentData && props.currentData.projectId && <Button variant="contained" size='medium' className="deleteButton" style={deleteStyle} onClick={() => setDeleteProject(true)}color="error">Delete</Button> } 
                </React.Fragment>  : (sessionStorage.getItem('environment') ? <Button variant="contained" size='medium' style={addStyle} className="addButton" onClick={handleClick}>Add project</Button> : <div className={`container_div`}></div>) }
                {deleteProject && <CommonModal mBody = {`Do you really want to delete the project ${props.currentData.projectId}`} title= {"Delete Project"} footer={"OK"} cancel={'OK'} onClick={removeProject} onCancel={() => setDeleteProject(false)}/>}
        </div>
    )
}
