import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from './App'; // Ensure this is your configured UserManager instance

const RedirectCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Process the redirect callback and then route the user back
        msalInstance.signinRedirectCallback().then(() => {
            console.log("Callback processed successfully");
            navigate('/'); // Redirect to home or a protected route
        }).catch(error => {
            console.error("Callback error:", error)
        });
    }, [navigate]);
    return <div>Redirecting......</div>;
};

export default RedirectCallback;

