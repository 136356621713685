import React, { useState } from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import CommonModal from '../Modals/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { consumeApplication as consumeAppAction } from '../../Actions/Dashboard/DashboardActions';

export default function LicenseApp(props) {
    const [alertModal, setAlertModal] = useState(false);
    const [licenseItem, setLicenseItem] = useState(null);
    const [consumeCount, setConsumeCount] = useState('');
    const [comment, setComment] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    // Get addFormData from the Redux store
    const addFormData = useSelector(state => state.dashboardDetails.addFormData);

    // Get the license usage information from addFormData
    const globalLicenseUsage = addFormData?.globalLicenseUsage || [];

    const inputText = (
        <div className="container1">
            <TextField
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label="License Item ID"
                value={props.license.licenseItemId}
                disabled={true}
                style={{ marginBottom: '15px' }}
            />
            <TextField
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label="Consume Count"
                value={consumeCount}
                onChange={(e) => setConsumeCount(e.target.value)}
                style={{ marginBottom: '15px' }}
            />
            <TextField
                fullWidth
                label="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{ marginBottom: '15px' }}
            />
        </div>
    );

    let isActive = addFormData.isActive;

    const handleOK = () => {
        // Validate if the required fields are not empty
        if (!consumeCount || !comment) {
            setErrorMessage('Consume count and comment fields are required.');
            setAlertModal(false)
            resetForm()
            return;
        }
        
        if (!isActive) {
            setErrorMessage('The project is inactive. Cannot consume.'); // Set error modal for inactive project
            setAlertModal(false);
            resetForm();
            return;
        }

        if (isNaN(consumeCount) || parseInt(consumeCount, 10) < 0) {
            setErrorMessage('Please enter valid consume count');
            setAlertModal(false)
            resetForm()
            return;
        }

        consumeApplication(); // Execute consumption logic and dispatch the action
        setAlertModal(false); // Close the modal
    };

    const resetForm = () => {
        //setLicenseItem(null);
        setConsumeCount('');
        setComment('');
    };
    
    function consumeApplication() {
        // Prepare data for dispatch
        let lastAccessDate = props.lastaccessdate;
        const givenDate = new Date(lastAccessDate);
        const today = new Date();
        const date60DaysAgo = new Date();
        date60DaysAgo.setDate(today.getDate() - 60);
        if (date60DaysAgo < givenDate) {
            setErrorMessage('The asset is a real system. Cannot consume.');
            resetForm()
            return;
        }
    
        let projId = addFormData.projectId;
        let appId = props.applicationId;
        let licenseItemId = props.license.licenseItemId;
    
        // Find the license item from globalLicenseStatus
        const licenseUsage = addFormData.globalLicenseStatus.find(
            (item) => item.licenseItemId === licenseItemId
        );
    
        // Retrieve availableCount for the selected licenseItemId
        let availableCount = licenseUsage ? licenseUsage.availableCount : 0;
    
        console.log('Available Count:', availableCount);
    
        // Check if consumeCount exceeds availableCount
        if (parseInt(consumeCount, 10) > availableCount) {
            setErrorMessage(`Error: Consume count (${consumeCount}) exceeds available count (${availableCount})`);
            resetForm()
            return;
        }
    
        // Proceed with consumption logic
        let existingConsumeCount = props.license.consumedCount;
        let finalConsumeCount = parseInt(consumeCount, 10) - existingConsumeCount;
    
        if (finalConsumeCount !== 0) {
            dispatch(consumeAppAction(projId, appId, finalConsumeCount, licenseItemId));
        }
    }
    
    return (
        <div key={props.key} className="licenseList">
            <TextField
                label="License ID"
                style={{ display: 'block', margin: '5px 0px 15px 0px' }}
                value={props.license.licenseItemId}
                disabled={true}
            />
            <TextField
                label="Consumed count"
                style={{ display: 'block', margin: '5px 0px 15px 0px' }}
                value={props.license.consumedCount}
                disabled={true}
            />
            <Button
                style={{ border: '1px solid #8561c5', color: '#8561c5', right: '-280px' }}
                size="small"
                onClick={() => setAlertModal(true)}
            >
                +
            </Button>
            {alertModal && (
                <CommonModal
                    onClick={handleOK} // Ensure this is correctly bound
                    onCancel={() => setAlertModal(false)}
                    mBody={inputText}
                    title={"Application Consumption"}
                    footer={"OK"}
                    cancel={'OK'}
                />
            )}

            {errorMessage && (
                <CommonModal
                    onClick={() => setErrorMessage('')}
                    onCancel={() => setErrorMessage('')}
                    mBody={<Typography>{errorMessage}</Typography>}
                    title={"Error"}
                    footer={"OK"}
                    cancel={'OK'}
                />
            )}
        </div>
    );
}
