import * as types from '../Actions/Dashboard/DashboardActionTypes';
const initialState = {
  setLoader: false,
  notificationData: []
};
const Project = function (state = initialState, action) {
  switch (action.type) {
    case types.LOAD_MODULE_LIST:
      return {
        ...state,
        accordionList: action.payload
      }
    case types.LOAD_GRID_DATA:
      return {
        ...state,
        gridData: action.payload
      }
    case types.SET_DETAIL_VIEW:
      return {
        ...state,
        isDetailPage: action.payload
      }
    case types.UPDATE_GRID_DATA:
      let resultData;
      if (state.gridData.searchRowData !== undefined) {
        resultData = {
          ...state.gridData,
          rowData: action.payload,
          searchRowData: action.payload
        }
      }
      else
        resultData = { ...state.gridData, rowData: action.payload }
      return {
        ...state,
        gridData: resultData

      }
    case types.CURRENT_PROJECT:
      return {
        ...state,
        addFormData: action.payload
      }
    case types.CLEAN_STORE:
      return initialState
    case types.CLEAR_FORM_DATA:
      return {
        ...state,
        addFormData: undefined
      }
    case types.STORE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }
    case types.SET_LOADER:
      return {
        ...state,
        setLoader: action.payload
      }
    case types.INVALID_PROJECT:
      return {
        ...state,
        validProject: action.payload
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.payload
      }
    case types.ADD_NOTIF:
      // let notifArray=[];
      // if(state.notificationData && state.notificationData.length > 0){
      //   state.notificationData.push(action.payload)
      //   notifArray = state.notificationData
      // }
      // else{
      //   notifArray.push(action.payload)
      // }
      return {
        ...state,
        alert: action.payload
      }
    case types.CLEAR_NOTIF:
      return {
        ...state,
        alert: {}
      }
    case types.NOTIF_CONTAINER:
      return {
        ...state,
        notificationData: [...state.notificationData, action.payload]
      }
    case types.NOTIF_COUNTER:
      let counter = action.payload[0].counter;
      const type = action.payload[0].type;
      if (action.payload[0].counter === undefined) {
        counter = (state.notificationCounter !== undefined && state.notificationCounter.counter !== undefined) ? state.notificationCounter.counter + 1 : 1;
      }
      return {
        ...state,
        notificationCounter: { counter: counter, type: type }
      }
    case types.TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: action.payload
      }
    case types.VALIDATE_USER_EMAIL:
      return {
        ...state,
        whiteListed: action.payload.flag,
        environment:action.payload.data.environment
      }
    default:
      return state;
  }
}
export default Project;