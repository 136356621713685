import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CommonModal from '../Modals/CommonModal';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { consumeApplication as consumeAppAction } from '../../Actions/Dashboard/DashboardActions';
const axios = require('axios');


// Custom Tooltip for consistent styling
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export default function GeneralApp(props) {
    const [alertModal, setAlertModal] = useState(false);
    const [licenseItem, setLicenseItem] = useState(null);
    const [consumeCount, setConsumeCount] = useState('');
    const [comment, setComment] = useState('');
    const [list, setList] = useState([]);
    const [consumelicid, setconsumelic] = React.useState('');
    const [labelFocus, setLabelFocus] = useState(false);
    const [licenseType, setLicenseType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.data && props.data.globalLicenseStatus) {
            console.log("propss", props.data);
            const licenseItems = props.data.globalLicenseStatus.map(item => ({
                label: `${item.licenseItemId} (Available: ${item.availableCount})`,
                ...item
            }));
            setList(licenseItems);
        }
    }, [props.data]);

    const handleChange = (newValue) => {
        setLicenseItem(newValue);
        // Fetch the license type for the selected licenseItem
        if (newValue && newValue.licenseItemId) {
            const selectedItem = props.data.globalLicenseStatus.find(
                (item) => item.licenseItemId === newValue.licenseItemId
            );
            if (selectedItem) {
                setLicenseType(selectedItem.licenseItemType); // Set the type automatically
            }
        }
    };

    const inputText = (
        <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="caption" display="block" gutterBottom>
                License Item ID
            </Typography>
            <Autocomplete
                notchedOutline={false}
                label="License Item ID"
                value={licenseItem}
                onChange={(event, newValue) => {
                    setLicenseItem(newValue);
                    handleChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setList([...list, { label: newInputValue }]);
                }}
                options={list}
                getOptionLabel={(option) => option.label}
                disableClearable
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={'selectOptionAuto'}
                        onFocus={() => setLabelFocus(true)}
                        onBlur={() => setLabelFocus(false)}
                        variant="standard"
                        style={{ width: '300px', margin: "0px 0px 10px 0px" }}
                    />
                )}
            />
            <Typography variant="caption" display="block" gutterBottom>
                License Type
            </Typography>
            {/* Automatically display the license type fetched from the props */}
            <TextField
                fullWidth
                variant="standard"
                value={licenseType || 'Unknown'}
                disabled
                style={{ marginBottom: "10px" }}
            />
            <Typography variant="caption" display="block" gutterBottom>
                Consume count
            </Typography>
            <TextField
                fullWidth
                variant="standard"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                style={{ marginBottom: "10px" }}
                value={consumeCount}
                onChange={(e) => setConsumeCount(e.target.value)}
            />
            <Typography variant="caption" display="block" gutterBottom>
                Comment
            </Typography>
            <TextField
                fullWidth
                variant="standard"
                style={{ marginBottom: "10px" }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
        </Box>
    );

    let isActive = props.data.isActive;
    console.log("active",isActive);
    
    const handleOK = () => {
        // Validate required fields
        if (!licenseItem || !consumeCount || !comment) {
            setErrorMessage('Please enter all required fields.');
            setAlertModal(false)
            resetForm()
            return;
        }

        if (!isActive) {
            setErrorMessage('The project is inactive. Cannot consume.'); // Set error modal for inactive project
            setAlertModal(false);
            resetForm();
            return;
        }

        if (isNaN(consumeCount) || parseInt(consumeCount, 10) < 0) {
            setErrorMessage('Please enter valid consume count');
            setAlertModal(false)
            resetForm()
            return;
        }
        consumeApplication();
        //setAlertModal(false);
    };

    const showModal = () => {
        setAlertModal(true);
    };

    const consumeApplication = () => {
        let lastAccessDate = props.app.lastAccessDate;
        let isActive = props.data.isActive;
        console.log("active",isActive);
        const givenDate = new Date(lastAccessDate);
        const today = new Date();
        const date60DaysAgo = new Date();
        date60DaysAgo.setDate(today.getDate() - 60);
    
        let projId = props.data.projectId;
        let appId = props.app.applicationId;
        let licenseItemId = licenseItem.licenseItemId;
    
        const applicationLicenseStatus = props.data.applicationLicenseStatus.find(
            (item) => item.applicationId === appId
        );
        let existingConsumeCount = 0;
    
        if (applicationLicenseStatus && applicationLicenseStatus.licenseItemUsage) {
            const usage = applicationLicenseStatus.licenseItemUsage.find(
                (usage) => usage.licenseItemId === licenseItemId
            );
            if (usage) {
                existingConsumeCount = usage.consumedCount;
            }
        }
    
        const selectedLicenseItem = list.find(item => item.licenseItemId === licenseItemId);
        const availableCount = selectedLicenseItem ? selectedLicenseItem.availableCount : 0;
    
        // Now checking for consistency in licenseType
        if (licenseItem && licenseItem.licenseItemType === "RESOURCE" && parseInt(consumeCount, 10) > availableCount) {
            setErrorMessage('The entered consume count is greater than the available count.'); // Set error modal
            setAlertModal(false)
            resetForm()
            return;
        }
    
        if (date60DaysAgo < givenDate) {
            setErrorMessage('The asset is a real system. Cannot consume.'); // Set error modal
            resetForm()
            return;
        }
    
        let finalConsumeCount = parseInt(consumeCount, 10) - existingConsumeCount;
        if (finalConsumeCount === 0) {
            return;
        }
    
        if ((lastAccessDate == "") || (date60DaysAgo > givenDate)) {
            dispatch(consumeAppAction(projId, appId, finalConsumeCount, licenseItemId));
            resetForm()
            setAlertModal(false);
        }
    };
    

    const resetForm = () => {
        setLicenseItem(null);
        setConsumeCount('');
        setComment('');
        setLicenseType('');
    };

    const handleOpenModal = () => {
        resetForm();
        setAlertModal(true);
    };

    return (
        <React.Fragment>
            <p className="general_text">General</p>
            <LightTooltip TransitionComponent={Zoom} title={props.app.applicationId ? props.app.applicationId : ''}>
                <TextField
                    id={`appId_${parseInt(props.index) + 1}`}
                    label="Application ID"
                    style={{ display: "block", margin: "5px 0px 15px 0px", width: '300px' }}
                    value={props.app.applicationId}
                    disabled={props.disabled}
                    inputProps={{ style: { width: 300, textOverflow: 'ellipsis' } }}
                    onChange={(e) => { props.changeAppId(e); }}
                />
            </LightTooltip>

            {props.app.lastAccessDate && (
                <TextField
                    id="applicationAccessDate"
                    label="Last access date"
                    style={{ display: "block", margin: "5px 0px 15px 0px", width: '300px' }}
                    value={props.app.lastAccessDate ? props.app.lastAccessDate : ' '}
                    disabled={props.disabled}
                    inputProps={{ style: { width: 300, textOverflow: 'ellipsis' } }}
                />
            )}

            <Button
                style={{ border: "1px solid #8561c5", color: "#8561c5", margin: "10px 0px" }}
                size="small"
                onClick={showModal}
            >
                Consume
            </Button>

            <div className="container">
                {alertModal && (
                    <CommonModal
                        onClick={handleOK}
                        onCancel={() => setAlertModal(false)}
                        mBody={inputText}
                        title={"Application Consumption"}
                        footer={"OK"}
                        cancel={'OK'}
                    />
                )}
            </div>

            {errorMessage && (
                <CommonModal
                    onClick={() => setErrorMessage('')}
                    onCancel={() => setErrorMessage('')}
                    mBody={<Typography>{errorMessage}</Typography>}
                    title={"Error"}
                    footer={"OK"}
                    cancel={'OK'}
                />
            )}

        </React.Fragment>
    );
}
