import React,{useState} from 'react';
import { Autocomplete, TextField, InputLabel } from '@mui/material';
import {dropDown} from '../Utils/Utility';
// import { autocompleteClasses } from '@mui/material/Autocomplete';
// import Popper from '@mui/material/Popper';
// import { styled } from '@mui/material/styles';
export default function SelectAutocomplete(props) {    
    const [list,setList] = useState(dropDown)
    const [labelFocus,setLabelFocus] = useState(false)
    const [value, setValue] = useState({label:""});
    function handleChange(value) {
        props.onChange({target: {value: value.label,id:props.id}})
    }
    console.log(list,'rerender');
    
    // const StyledPopper = styled(Popper)({
    //   [`& .${autocompleteClasses.listbox}`]: {
    //     boxSizing: 'border-box',
    //     '& ul': {
    //       padding: 0,
    //       margin: 0,
    //       border:0
    //     },
    //   },
    // });
    return <React.Fragment>
        <InputLabel className={labelFocus && 'label_blue'} variant="standard" style={{fontSize:'15px',margin:"6px 0 6px 0px",border:"0px 0px 1px 0px"}}>
          License Item ID
        </InputLabel>
        <Autocomplete
        notchedOutline = {false}
        label="License Item ID"
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                handleChange(newValue)
            }} 
            onInputChange={(event, newInputValue) => {
                setList([...dropDown,{label:newInputValue}]);
              }}      
            options={list}
            // onFocus={setLabelFocus(true)}
            // onBlur={setLabelFocus(false)}
            getOptionLabel={option => option.label}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                className={'selectOptionAuto'}
                onFocus={() => setLabelFocus(true)}
                onBlur={() => setLabelFocus(false)}
                variant="outlined"
                style={{borderBottom:"2px solid #ccc",paddingBottom:"0px",width:'300px',margin:"0px 0px 10px 15px"}}
              />
            )}
        />
    </React.Fragment>
}
