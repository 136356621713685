import React, { useRef, useEffect } from 'react'
import {groupBy,getAllItemFromObjects} from '../Utils/Utility';
import successLogo from '../Assets/Images/success.png';
import errorLogo from '../Assets/Images/error.png';
import warningLogo from '../Assets/Images/alert.png';
export default function HeaderNotification(props) {
    const [status,setStatus] = React.useState('success');
    let ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && props.parentRef.current && !props.parentRef.current.contains(event.target)) {
            props.onClickOutside && props.onClickOutside();
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    function changeStatus(e){
        setStatus(e.target.getAttribute('name'))
    }    
    let grouped = groupBy(props.data, 'type');
    console.log(status,'notif');
    let currentSection = status !== 'all' ? grouped[status] : getAllItemFromObjects(grouped)
    
    console.log(currentSection,status,grouped,'groupedData')
    // currentSection && currentSection.length > 0 && currentSection.reverse();
    currentSection && currentSection.length > 0 && currentSection.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });
    let messages = currentSection && currentSection.length > 0 ? currentSection.map(item => {
        let logo = item.type === 'warning' ? warningLogo : item.type=== 'failure' ? errorLogo : successLogo;
            return <div className='notifMessage'>
                <img src={logo} alt='notification symbol' style={{width:'24px',height:"24px"}}/>
                {/* <span style={{    color: 'rgb(133, 97, 197)',textTransform:'capitalize', minWidth:"80px" }}>{item.type}</span> */}
                <span style={{color:'#000',fontSize:"15px",fontWeight:"650"}}>{item.date.toLocaleString()}</span>
                <span style={{color:'#000',fontSize:"15px"}}>{item.message}</span>
            </div>
    }) : <span style={{color:'black',padding:'20px',fontSize:"20px",display:"inline-block"}}>No {status !== 'all' ? status : ''} Messages</span>
    return (
        <div className={`header_notification`} ref={ref}>
            <ul className={'notif_cont'}>
                <li className={`notif_list all ${status === 'all' ? 'active' : ''}`} name='all' onClick={changeStatus} > 
                    All
                </li>
                <li className={`notif_list success ${status === 'success' ? 'active' : ''}`} name='success' onClick={changeStatus} > 
                    Success
                </li>
                <li className={`notif_list error ${status === 'failure' ? 'active' : ''}`} name='failure' onClick={changeStatus} >
                    Error
                </li>
                <li className={`notif_list warning ${status === 'warning' ? 'active' : ''}`} name='warning' onClick={changeStatus} >
                    Warning
                </li>
            </ul>
            <ul style={{padding:'0px 0px 20px 0px',margin:"0px",fontFamily:"system-ui",overflowY:"auto",display:"flex",flexDirection:"column",maxHeight:"245px",}}>
                {messages}
            </ul>
        </div>
    )
}
