/* eslint-disable no-unused-expressions */
import * as types from './DashboardActionTypes';
import * as constants from '../../Utils/constants';
import * as util from '../../Utils/Utility';
// import { useMsal } from "@azure/msal-react";


export const setDetailView = (isDetail) => dispatch => {
    dispatch({
        type: types.SET_DETAIL_VIEW,
        payload: isDetail
    })
}
export const setLoader = (data) => (dispatch) => {
    dispatch({
        type: types.SET_LOADER,
        payload: data
    })
}
export const saveProject = (data) => (dispatch, getState) => {
    let state = getState();
    let mode = state.dashboardDetails.mode;
    dispatch(setLoader(true))
    let deleteLicenseItems = false;
    if (data.globalLicenseStatus.length === 1) {
        let license = data.globalLicenseStatus[0];
        if (license.licenseItemId === "" && license.subscribedCount === '') {
            deleteLicenseItems = true;
        }
    }
    let bodyParams = {

    }
    bodyParams.mode = mode
    bodyParams.isActive = data.isActive ? data.isActive : false;
    bodyParams.licenseItems = deleteLicenseItems === true ? [] : data.globalLicenseStatus.map(item => {
        let result = {};
        result = {
            licenseItemId: item.licenseItemId,
            licenseItemType: util.getLabelValues(item.licenseItemType),
            subscribedCount: item.licenseItemType.toLowerCase() === "resource" ? parseInt(item.subscribedCount) : util.convertToBool(item.subscribedCount)
        }
        if (item.comment !== undefined && item.comment !== '')
            result = { ...result, comment: item.comment }
        return result;
    })
    let url = constants.createProject + data.projectId
    fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(bodyParams) // body data type must match "Content-Type" header
    })
        .then(data => data.json())
        .then(data => {
            if (data.status === 401) {
                dispatch(tokenExpired(true));
            }
            dispatch(distpatchNotification(data))
            dispatch(setDetailView(false))
            dispatch(setCounterValue('success'))
            dispatch(setLoader(false))
            if (data.status === 409) {
                dispatch(setDetailView(false))
                dispatch(distpatchNotification(data))
                dispatch(setLoader(false))
                dispatch(setCounterValue('error'))
            }
        })
        .catch(err => {
            dispatch(setCounterValue('error'))
            dispatch(setLoader(false))
            dispatch(setDetailView(false))
        })
}

export const distpatchNotification = (notifObj) => (dispatch) => {
    let type = 'failure';
    if (notifObj.status === 200) {
        type = "success";
    }
    else if (notifObj.status === 405) {
        type = "warning";
    }
    dispatch({
        type: types.NOTIF_CONTAINER,
        payload: { type: type, message: notifObj.message, date: new Date() }
    })
    dispatch({
        type: types.ADD_NOTIF,
        payload: { type: type, message: notifObj.message }
    })
}
export const clearNotification = () => (dispatch) => {

    dispatch({
        type: types.CLEAR_NOTIF
    })
}
export const deleteProject = (projectId) => (dispatch) => {
    if (!projectId) {
        let errorMessage = util.constructInvalidErrorObject(util.errorMessages.invalid);
        dispatch(distpatchNotification(errorMessage))
        return;
    }
    dispatch(setLoader(true))
    let url = constants.createProject + projectId;
    console.log(url, 'deleteProject')
    fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
        .then(data => data.json())
        .then(data => {
            if (data.status === 401) {
                dispatch(tokenExpired(true));
            }
            let notification = {}
            console.log(data)
            data.status !== 401 && data.status !== 409 && dispatch(setDetailView(false))
            if (data.status === undefined) {
                notification.status = 200;
                notification.message = `Project ${projectId} deleted successfully`;
                dispatch(distpatchNotification(notification));
            }
            else
                dispatch(distpatchNotification(data));
            dispatch(setCounterValue('success'))
            dispatch(setLoader(false))
        })
        .catch(err => {
            dispatch(setCounterValue('error'))
            dispatch(setLoader(false))
            dispatch(distpatchNotification(err));
            console.log(err)
        })
}
export const saveAppData = (appId, projectId) => (dispatch, getState) => {
    dispatch(setLoader(true))
    if (appId) {
        // let newAppId = appData[appData.length-1].applicationId;
        let url = constants.createProject + projectId + `/${appId}`;
        fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: util.getHeaders(),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        })
            .then(data => data.json())
            .then(data => {
                if (data.status === 401)
                    dispatch(tokenExpired(true));
                dispatch(distpatchNotification(data))
                // dispatch(setDetailView(false))
                let notif = false;
                if (data.status !== 409 && dispatch(findProject(projectId, notif))) {
                    dispatch(distpatchNotification(data))
                    dispatch(setCounterValue('success'))
                }
                if (data.status === 409) {
                    dispatch(distpatchNotification(data))
                    dispatch(setLoader(false))
                    dispatch(setCounterValue('error'))
                }
            })
            .catch(err => {
                dispatch(setCounterValue('error'))
                dispatch(setLoader(false))
                dispatch(setDetailView(false))
            })
    }
}

export const consumeApplication = (projId, appId, consumeCount, licenseItemId) => (dispatch) => {
    //let projectId="boss"
    if (!projId || !appId) {
        let errorMessage = util.constructInvalidErrorObject(util.errorMessages.invalid);
        dispatch(distpatchNotification(errorMessage))
        return;
    }
    dispatch(setLoader(true))
    let url = constants.simulatelicense + appId;
    let reqbody = {
        projId: projId,
        consumeCount: consumeCount,
        licenseItemId: licenseItemId
    }
    fetch(url, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        body: JSON.stringify(reqbody),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
        .then(data => data.json())
        .then(data => {
            if (data.status === 401) {
                dispatch(tokenExpired(true));
            }
            let notification = {}
            console.log(data)
            if (data.status !== 401 && data.status !== 409) {
                dispatch(setLoader(true))
                let notif = false;
                data.status !== 409 && dispatch(findProject(projId, notif))
                // dispatch(setDetailView(false))
            }
            if (data.status === undefined) {
                notification.status = 200;
                notification.message = `Application ${appId} consumed successfully`;
                dispatch(distpatchNotification(notification));
            }
            else
                dispatch(distpatchNotification(data));
            // dispatch(setCounterValue('success'))
            data.status === 409 && dispatch(setLoader(false))
        })
        .catch(err => {
            dispatch(setCounterValue('error'))
            dispatch(setLoader(false))
            dispatch(distpatchNotification(err));
            console.log(err)
        })

}

export const deleteApplication = (projectId, appId) => (dispatch) => {
    console.log("deleteeee")
    if (!projectId || !appId) {
        let errorMessage = util.constructInvalidErrorObject(util.errorMessages.invalid);
        dispatch(distpatchNotification(errorMessage))
        return;
    }
    dispatch(setLoader(true))
    let url = constants.createProject + projectId.trim() + '/' + appId.trim();
    console.log(url, 'deleteApp')
    fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
        .then(data => data.json())
        .then(data => {
            if (data.status === 401) {
                dispatch(tokenExpired(true));
            }
            let notification = {}
            console.log(data)
            if (data.status !== 401 && data.status !== 409) {
                dispatch(setLoader(true))
                let notif = false;
                data.status !== 409 && dispatch(findProject(projectId, notif))
                // dispatch(setDetailView(false))
            }
            if (data.status === undefined) {
                notification.status = 200;
                notification.message = `Application ${appId} deleted successfully`;
                dispatch(distpatchNotification(notification));
            }
            else
                dispatch(distpatchNotification(data));
            // dispatch(setCounterValue('success'))
            data.status === 409 && dispatch(setLoader(false))
        })
        .catch(err => {
            dispatch(setCounterValue('error'))
            dispatch(setLoader(false))
            dispatch(distpatchNotification(err));
            console.log(err)
        })

}
export const findProject = (searchElem, notif) => (dispatch) => {
    if (!searchElem || searchElem.trim() == '') {
        let errorMessage = util.constructInvalidErrorObject(util.errorMessages.invalid);
        dispatch(setCounterValue('warning'))
        dispatch(distpatchNotification(errorMessage))
        return;
    }
    dispatch(setLoader(true))
    let url = constants.createProject + searchElem;
    fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
        .then(data => data.json())
        .then(async (data) => {
            if (data.status === 401) {
                dispatch(tokenExpired(true));
            }
            let status = 'success'
            let notification = {}
            console.log(data)
            dispatch({
                type: types.CURRENT_PROJECT,
                payload: data
            })
            data.status !== 401 && data.status !== 409 && dispatch(setDetailView(true))
            if (data.status === undefined) {
                notification.status = 200;
                notification.message = "Project fetch successful";
                notif !== false && dispatch(distpatchNotification(notification));
            }
            else
                notif !== false && dispatch(distpatchNotification(data));
            if (data.status === 401 || data.status === 409)
                status = 'error'
            dispatch(setCounterValue(status))
            dispatch(setLoader(false))
        })
        .catch(err => {
            dispatch(setCounterValue('error'))
            dispatch(setLoader(false))
            notif !== false && dispatch(distpatchNotification(err));
            console.log(err)
        })
}
export const changeStatus = (status, projectId) => (dispatch) => {
    console.log(status, `status`)
    dispatch(setLoader(true))
    let url = constants.createProject + `${projectId}/state`
    fetch(url, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(status) // body data type must match "Content-Type" header
    })
        .then(data => data.json())
        .then(data => {
            if (data.status === 401) {
                dispatch(tokenExpired(true));
            }
            console.log(data, `status`)
            dispatch(distpatchNotification(data))
            dispatch(setLoader(false))
            dispatch(setCounterValue('success'))
        })
        .catch(err => {
            dispatch(setCounterValue('error'))
            dispatch(setLoader(false))
        })
}
export const setValidProject = (valid) => (dispatch) => {
    dispatch({
        type: types.INVALID_PROJECT,
        payload: valid
    })
}
export const resetStore = () => (dispatch) => {
    dispatch({
        type: types.CLEAN_STORE
    })
}

export const clearFormData = () => (dispatch, getState) => {
    dispatch({
        type: types.CLEAR_FORM_DATA
    })
}
export const storeAccessToken = (token) => (dispatch) => {
    dispatch({
        type: types.STORE_ACCESS_TOKEN,
        payload: token
    })
}
export const setMode = (mode) => (dispatch) => {
    dispatch({
        type: types.SET_MODE,
        payload: mode
    })
}
export const setCounterValue = (type, counter) => (dispatch) => {
    let data = [{
        counter: counter,
        type: type
    }]
    dispatch({
        type: types.NOTIF_COUNTER,
        payload: data
    })
}
export const tokenExpired = (flag) => (dispatch) => {
    dispatch({
        type: types.TOKEN_EXPIRED,
        payload: flag
    })
}
export const validateUsers = (access_token) => (dispatch) => {
    let bodyParams = { token: access_token }
    dispatch(setLoader(true))
    let url = constants.authUser;
    fetch(url, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: util.getHeaders(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(bodyParams)
    })
        .then(data => data.json())
        .then((data) => {
            dispatch(setLoader(false))
            if (data.status === 401) {
                dispatch({
                    type: types.VALIDATE_USER_EMAIL,
                    payload: false
                })
            }
            else {
                sessionStorage.setItem('environment', data.environment);
                dispatch({
                    type: types.VALIDATE_USER_EMAIL,
                    payload: { flag: true, data: data }
                })
            }
        })
        .catch((err) => {
            dispatch(setLoader(false))
            console.log(err)
        })
}