export const LOAD_MODULE_LIST = "LOAD_MODULE_LIST";
export const LOAD_GRID_DATA = "LOAD_GRID_DATA";
export const SET_DETAIL_VIEW = "SET_DETAIL_VIEW";
export const UPDATE_GRID_DATA = "UPDATE_GRID_DATA";
export const UPDATE_SEARCH_GRID = "UPDATE_SEARCH_GRID";
export const CLEAN_STORE = "CLEAN_STORE";
export const CURRENT_PROJECT = "CURRENT_PROJECT";
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";
export const STORE_ACCESS_TOKEN = "STORE_ACCESS_TOKEN";
export const SET_LOADER = "SET_LOADER";
export const INVALID_PROJECT = "INVALID_PROJECT";
export const SET_MODE = "SET_MODE";
export const ADD_NOTIF = "ADD_NOTIF";
export const CLEAR_NOTIF = "CLEAR_NOTIF";
export const NOTIF_CONTAINER = "NOTIF_CONTAINER";
export const NOTIF_COUNTER = "NOTIF_COUNTER";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const INVALID_USER = "INVALID_USER";
export const VALIDATE_USER_EMAIL = "VALIDATE_USER_EMAIL";