/* eslint-disable no-unused-expressions */
import React from 'react'
import { Box, Tab } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import AddForm from './AddForm';
import Application from './Application';

export default function AddProject(props) {
    const [mode, setMode] = React.useState('project')
    function handleChange(e) {
        if(props.detailViewData && props.detailViewData.projectId){        
            let isApp = e.currentTarget.className.indexOf('appTab') >= 0;
            if((isApp && mode === 'app') || (!isApp && mode === 'project'))
            return
            mode === 'project' ? setMode("app") : setMode('project')
        }
    }
    let init = {};
    if(props.detailViewData !== undefined)
    {
        init = props.detailViewData
    }
    function addProject(data) {
        props.actions.saveProject(data)
        props.actions.setDetailView(false)
    }
    function saveAppData(appId,projectId){
        props.actions.saveAppData(appId,projectId)
    }
    function deleteApplication(projectId,appId){
        props.actions.deleteApplication(projectId,appId)
    }
    function changeStatus(status,projectId){
        props.actions.changeStatus(status,projectId)
    }
    console.log(props,'test');
    return (
        <div style={{width:"1080px",margin:"0 auto"}}>
            <TabContext value={mode}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab className={'projectTab'} label="Project" value="project" />
                        <Tab className={'appTab'} label="Application" value="app" disabled={props.detailViewData !== undefined ? false : true} />
                    </TabList>
                </Box>
                
                <TabPanel value="project">
                    <AddForm mode={props.mode} data={init} storeProjectDetails={addProject} changeStatus={changeStatus}/>
                </TabPanel>
                <TabPanel value="app" style={{padding:'20px 0px'}}>
                    <Application data={init} environment={props.environment} projectId={props.detailViewData && props.detailViewData.projectId} save={saveAppData} deleteApp={deleteApplication}/>
                </TabPanel>
            </TabContext>

        </div>
    )
}
