import * as React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, 
    Typography,styled } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(5),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const dialogStyle = {minWidth:"400px"}
  return (
    <DialogTitle sx={{ m: 2, p: 5 }}  
    {...other}
    style = {dialogStyle}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CommonModal(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.setDetailView && props.setDetailView(props.detail)
    setOpen(false);
    props.reinitiateFlag && props.reinitiateFlag(false)
  };
  const onCLick = () => {
    handleClose();
    props.onClick()
  }
  const onCancel = () => {
    props.onCancel && props.onCancel()
    handleClose()
  }
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onBackdropClick = 'false'
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {props.mBody}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.onClick ? onCLick : handleClose}>
            {props.footer}
          </Button>
          {props.cancel && <Button onClick={onCancel}>Cancel</Button>}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
