import React from 'react'
import { TextField, Button } from '@material-ui/core';
import SelectOption from '../../../Widget/SelectOption';
import * as util from '../../../Utils/Utility'
import SelectAutocomplete from '../../../Widget/SelectAutocomplete';
export default function LicenseItems(props) {
    function onChange(e) {
        props.onChange(e)

    }
    const [init, setInit] = React.useState(props.data);
    const blockInvalidChar = e => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    const licenseItemTypeOptions = ['Resource', 'Boolean'];
    const licenseWrapper = { margin: "10px auto", width: "320px", padding: "15px", border: "1px solid #ccc", borderRadius: "4px", position: "relative" }
    let licenseItems = props.data.map((item, i) => {
        let disabled = true;
        let comment = false;
        const id = i + 1;
        if (item.availableCount === undefined)
            disabled = false
        if (init[i] && parseInt(init[i].subscribedCount) !== parseInt(item.subscribedCount)) {
            comment = true;
            item.comment = item.comment ? item.comment : '';
        }
        else {
            disabled && delete item.comment;
        }
        let optionDisable = util.booleanTypeOptions.indexOf(item.licenseItemId) >= 0;
        // if(optionDisable || item.licenseItemType.toLowerCase() === 'boolean'){
        //     item.licenseItemType = 'Boolean';
        // //     // eslint-disable-next-line no-self-assign
        //     // if(item.subscribedCount === '')
        //     item.subscribedCount === "" ? item.subscribedCount = 'True' : item.subscribedCount = item.subscribedCount;
        // }
        // else{
        //     item.licenseItemType = 'Resource';
        //     // item.subscribedCount !== ''  ? item.subscribedCount = item.subscribedCount : item.subscribedCount = 'True';
        // }
        let typeValue = item.licenseItemType.toLowerCase() === 'boolean';
        return (
            <div className="license_wrapper" data-id={id} key={id} style={licenseWrapper} >
                <div className="filed_wrapper" style={{ display: "inline-block" }}>
                    {(props.mode === "ADD" || !disabled) &&
                        <SelectAutocomplete onChange={onChange} id={`licenseItemId_${id}`} />}
                    {props.mode !== "ADD" && disabled &&
                        <TextField
                            maxWidth="sm"
                            fullWidth
                            label="License Item ID"
                            id={`licenseItemId_${id}`}
                            disabled={disabled}
                            style={props.commonStyle}
                            value={item.licenseItemId}
                        />}
                    {!disabled && <SelectOption onChange={onChange}
                        label={'License Item Type'}
                        id={`licenseItemType_${id}`}
                        defaultValue={item.licenseItemType}
                        options={optionDisable ? ['Boolean', 'Resource'] : licenseItemTypeOptions}></SelectOption>}
                    {/* {!disabled && <TextField maxWidth="sm" fullWidth label="License Type" id={`licenseItemType_${id}`} style={commonStyle} value={item.licenseItemType} onChange={props.onChange} />} */}
                    {disabled &&
                        <TextField
                            maxWidth="sm"
                            fullWidth
                            disabled={disabled}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            label="Available count"
                            style={props.commonStyle}
                            value={item.availableCount} />}
                    {typeValue ? <SelectOption value={util.convertToString(item.subscribedCount)}
                        onChange={props.onChange}
                        label={'Subscribed count'}
                        id={`subscribedCount_${id}`}
                        defaultValue={item.subscribedCount} options={["1", "0"]}>
                    </SelectOption> :
                        <TextField
                            type="number"
                            onKeyDown={blockInvalidChar}
                            maxWidth="sm"
                            fullWidth
                            inputProps={{ min: '0', pattern: '[0-9]*' }}
                            id={`subscribedCount_${id}`}
                            onChange={props.onChange}
                            label="Subscribed count"
                            style={props.commonStyle}
                            value={item.subscribedCount} />}
                    {/* {!disabled && <TextField maxWidth="sm" id={`comment_${id}`} fullWidth label="Comment" style={props.commonStyle} onChange={props.onChange} value={item.comment}/>} */}
                    {(!disabled || comment) &&
                        <TextField
                            maxWidth="sm"
                            id={`comment_${id}`}
                            fullWidth
                            label="Comment"
                            style={props.commonStyle}
                            onChange={props.onChange}
                            value={item.comment} />}
                </div>
                <div className="button_wrapper" data-id={id} style={{
                    position: "absolute",
                    top: "calc(50% - 28.18px)",
                    padding: "10px 0px",
                    backgroundColor: "#fff",
                    right: props.globalLicenseStatus.length === i + 1 ? '-125px' : '-100px'
                }}>
                    {props.globalLicenseStatus.length === i + 1 && <Button id={"plus_license"} style={props.buttonStyle} onClick={props.addLicenseItem}>+</Button>}
                    <Button
                        id={"minus_license"}
                        style={props.buttonStyle}
                        onClick={props.deleteLicenseItem}
                    >-
                    </Button>
                </div>
            </div>
        )
    })
    return (
        <div>
            {licenseItems}
        </div>
    )
}
