import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import * as actionCreators from '../../Actions/Dashboard/DashboardActions';

const mapStateToProps = (state) => ({
    accordion:state.dashboardDetails.accordionList,
    isDetailPage: state.dashboardDetails.isDetailPage,
    gridData: state.dashboardDetails.gridData,
    loginDetails: state.loginDetails,
    addFormData: state.dashboardDetails.addFormData,
    setLoader: state.dashboardDetails.setLoader,
    validProject: state.dashboardDetails.validProject,
    alert:state.dashboardDetails.alert,
    notification: state.dashboardDetails.notificationData,
    token:state.dashboardDetails.accessToken,
    mode:state.dashboardDetails.mode,
    notificationCounter:state.dashboardDetails.notificationCounter,
    tokenExpired:state.dashboardDetails.tokenExpired,
    whiteListed:state.dashboardDetails.whiteListed,
    environment:state.dashboardDetails.environment
    // searchGrid: state.dashboardDetails.gridData.searchRowData
});

const mapDispatchToProps = (dispatch) => ({
  actions : bindActionCreators(actionCreators, dispatch)
});

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardContainer;
