import React, { useState, useEffect } from 'react';
import DashboardHeader from './DashComp/DashboardHeader.js';
import DashboardBody from './DashComp/DashboardBody';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
//import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import * as util from '../../Utils/Utility';
import CircularIndeterminate from '../commonComponents/Loader';
import Alerts from '../commonComponents/Alerts';
import CommonModal from '../Modals/CommonModal.js';

const userManager = new UserManager({
    authority: 'https://sso.al-enterprise.com/am/oauth2/realms/root/realms/internal',
    client_id: 'LMS-INT',
    redirect_uri: 'https://int.wbm.lms.opentouch.com/',
    response_type: 'code',
    scope: 'openid profile email',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export default function Dashboard(props) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        userManager.getUser().then((user) => {
            if (user) {
                setUser(user);
                props.actions.storeAccessToken(user.access_token);
                sessionStorage.setItem("accessToken", user.access_token);
            } else {
                userManager.signinRedirect();
            }
            setLoading(false);
        }).catch((error) => {
            console.error('Error getting user:', error);
            setLoading(false);
        });

        return () => {
            props.actions.resetStore();
            console.log("cleaned up");
        };
    }, [props.actions]);

    const logoutRedirect = () => {
        userManager.signoutRedirect();
    };

    if (loading) {
        return <CircularIndeterminate />;
    }

    return (
        <>
            {user ? (
                <div>
                    <DashboardHeader
                        notificationCounter={props.notificationCounter}
                        CounterloginActions={props.loginActions}
                        actions={props.actions}
                        notification={props.notification}
                    />
                    <DashboardBody
                        searchGrid={props.gridData && props.gridData.searchRowData}
                        actions={props.actions}
                        accordionData={props.accordion}
                        isDetail={props.isDetailPage}
                        gridData={props.gridData}
                        account={user}
                        formData={props.addFormData}
                        validProject={props.validProject}
                        mode={props.mode}
                        environment={props.environment}
                    />
                    {props.alert && Object.keys(props.alert).length > 0 && <Alerts data={props.alert} action={props.actions} />}
                </div>
            ) : (
                props.whiteListed === false &&
                <CommonModal
                    mBody={`Your email is not whitelisted in LMS`}
                    title={"Unauthorized user"}
                    footer={"OK"}
                    onClick={logoutRedirect}
                />
            )}
        </>
    );
}
